<template>
    <div>
        <strong>Batching Utilization Data Upload</strong>
        <br>
        <div class="form-group">
            <label>Title</label>
            <input v-model="state.fileName" type="text" class="form-control" />
            <em>Uploaded file should be a CSV</em>
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";

    export default {
        name: "BatchUtilizationData",
        data() {
            return {
                state: fileUploadStore.state
            }
        },
        mounted() {
            this.state.referenceID = 1;
        }
    }
</script>

<style scoped>

</style>